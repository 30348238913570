.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 230px;
}

.image {
    width: 100%;
    height: 150px;
}

.text {
    font-style: italic;
}

.name {
    align-self: flex-end;
}