.mainTitle{
    font-size: 45;
    font-family: 'Handlee';
    font-weight: bold;
    color: black;
    text-align: center;
}

.white{
    color: white;
}