.container{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    min-height: 100%;
}

.image {
    object-fit: cover;
    object-position: 0;
    z-index: -4;
    opacity: 0.2;
    width: 100%;
    min-height: 100%;
}

.mask{
    background-color: black;
    z-index: -5;
    opacity: 0.9;
    width: 100%;
    min-height: 100%;
}