.button {
    border: none;
    width: fit-content;
    align-self: center;
    padding: 15px 30px;
    /* background-image:  linear-gradient(290deg, var(--secondary) 30%, var(--secondary)  100%); */
    background-color: black;
    color: var(--detail);
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
    transition: all .2s;
    cursor: pointer;
    margin-top: 80px;
    z-index: 10;
}

.button:hover{
    transform: scale(1.1);
}

.white {
    background-image: none;
    background-color: white;
    color: var(--azul);
}