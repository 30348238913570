@media (max-width: 1000px) {
    .container{
        flex-direction: column !important;
        text-align: center;
        align-items: center;
        margin-bottom: 100px;
    }
}

.container{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    min-height: 100vh;
    width: 100%;
}
.row{
    flex-direction: row;
}

.col{
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 20px;
}