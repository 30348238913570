.container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100px;
    text-align: center;
}
.container span {
    color: var(--azul);
    font-size: 60px;
}

.container label{
    font-size: 15px
}