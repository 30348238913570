@media (max-width: 600px) {
    .container{
        justify-content: center;
    }
}

.container {
    display: flex;
    gap: 20px;
    align-items: center;
}

.linksContainer{
    display: flex;
    gap: 20px
}

.linksContainer  img {
    width: 30px;
}