.textContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 0 50px;
    gap: 30px;
}
.image, .photoContainer {
    align-self: center;
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 400px !important;
    height: 500px !important;
    padding: 0;
    border-radius: 5px;
    margin: 0;
    border: none;
    top: 0;
    left: 0;
}
.image {
    object-fit: cover;
    position: absolute;
}

.photoContainer{
    position: relative;
    background-color: black;
    opacity: 0.9;
}

.textContainer img{
    max-width: 80vw;
}
.mini{
    color: white;
    font-size: 14px;
}

.iconsContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 70%;
    gap: 100px;
}

.logo {
    padding: 20px;
    width: 600px;
    background-color: var(--azul);
    border-radius: 12px;
}

@media (max-width: 600px) {
    .logo {
        padding: 0;
        max-width: 80%;
    }    
}

@media (max-width: 1000px) {
    .logo {
        margin: 50px 0;
    }    
}