@media (max-width: 600px) {
    .container{
        justify-content: space-around !important;
        padding: 0 !important;
    }
    .linksContainer{
        justify-content: center;
        gap: 10px !important;
    }
    .linksContainer a {
        max-width: 30% !important;
        font-size: 15px;
    }
    .logoContainer{
        font-size: 18px !important;
    }

    .container img {
        width: 120px !important;
    }

}

.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;
    width: 100%;
    color: white;
    border-bottom: 1px solid #ccc;
}
.logoContainer{
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 5px;
    font-style: italic;
    font-weight: 500;
}

.container img {
    width: 50px;
    border-radius: 5px;
}

.linksContainer {
    display: flex;
    gap: 30px
}

.linksContainer a{
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.linksContainer a:hover{
    color: #eee;
}