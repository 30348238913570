.middleText{
    font-size: 16px;
    font-weight: 300;
    max-width: 800px !important;
    text-align: center;
    line-height: 25px;
    align-self: center;
    white-space: pre-line;
    color: black;
    font-family: 'Handlee';
}

@media (max-width: 600px) {
    .middleText + .middleText{
        text-align: center;
    }
}
